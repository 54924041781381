































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    }),
    communityImages(): string[] {
      return [this.oss.cyberblade_swiper_01_01];
    },
    communityNames(): string[] {
      return this.isCN ? ["by 青空"] : ["by 青空"];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_relic_80);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  }
});
